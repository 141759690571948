<template>
    <div class="faq">
        <Header :pageId1="pageId" :cId1="cId" />
        <div class="banner">
            <div class="container">
                <div class="title">Sell Car Online</div>
            </div>
        </div>

        <div class="main">

            <div class="container">

                <div class="warpper">

                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h2>Make Selling Much Easier With Us and Sell Your Car Online</h2>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <p>If you think it’s time for a new ride, why not <a href="./about.html">sell your car
                                    online</a> so you can get your new car even faster? Our team understands what a hassle
                                it can become to find the right buyer for your vehicle, which is why our platform makes it
                                so much simpler. When you choose Sell My Cars Fast, you save time and money.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>What Benefits You Should Expect When You Sell a Used Car Online</h3>
                            <p>Unlike traditional methods of selling your car at a brick-and-mortar dealership, you get more
                                benefits when you come to our team. To better understand why we would recommend you use our
                                services, consider some of the benefits to the process:</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-6">
                            <ul>
                                <li><strong>Getting a quote for your vehicle is super simple.</strong> When you want a quote
                                    for your car, the only thing you need to do is submit an online form or contact us with
                                    the vehicle’s details through email. Once we’ve reviewed the details, we’ll put together
                                    a fair quote for your car and send it back to you.</li>
                                <li><strong>You can expect a dealer-beating price from our team.</strong> We believe that
                                    you should get the best price for your car, and we’re sure we’ll offer you a
                                    dealer-beating price every time. You know what your vehicle is worth, and you should get
                                    a fair price for it.</li>
                                <li><strong>You get your money much faster than you would through a dealership.</strong>
                                    Once we’ve agreed on a price for your car, you can expect the payment to come through
                                    within 24h. Compared to traditional dealerships, our payment process is much faster. We
                                    understand you don’t want to wait around for your money, so we emphasise speed to
                                    support your plans.</li>
                                <li><strong>We buy any car, no matter the make or model.</strong> Unlike many dealerships,
                                    you won’t find us turning our nose up at your car and refusing to buy it. Whatever the
                                    make and model of your vehicle, you can expect our team to make a fair offer and always
                                    buy it should you agree to the price.</li>
                            </ul>
                        </div>
                        <div class="ddCol-6">
                            <img src="~@/assets/images/choose.png">
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>Questions to Consider When You Want to Sell Your Car Online Instantly</h3>
                            <p>Since our business started, we’ve received some recurring questions from clients such as ‘how
                                do I get a sell my car quick quote?’ To help you with some immediate answers, here are some
                                of the most usual questions we receive:</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-6">
                            <img src="~@/assets/images/row2_l.png">
                        </div>
                        <div class="ddCol-6">
                            <ul>
                                <li><strong>How do you sell my car online and quote me for it?</strong> Our team provides
                                    you with a quote once we’ve reviewed your online form submission about your vehicle.
                                    These quotes are for you to consider, and if you agree to them, we will buy your car
                                    immediately. There is no faster way to sell your vehicle.</li>
                                <li><strong>What paperwork do you need from me before you can buy my car?</strong> The main
                                    thing we need from you is to confirm that you are the vehicle’s legal owner. The only
                                    things we need to ensure this is your driver’s license and your vehicle’s registration
                                    number. This allows our professionals to do the necessary checks before we can buy your
                                    car.</li>
                                <li><strong>Can I sell a vehicle on behalf of someone else?</strong> Absolutely! However,
                                    you must note that before you can do this, we will need the ID documents of the owner.
                                    We also need a letter from the owner stating that they have authorised you to sell their
                                    vehicle.</li>
                                <li><strong>How long before you collect my vehicle?</strong> Once we’ve bought the car from
                                    you, we will set a time and date most convenient for both parties. Please make sure that
                                    all necessities are in the car. These include the logbook, keys, and manuals.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>Why Trust Our Team When You Want to Sell Your Car Quickly Online</h3>
                            <p>Our team understands how frustrating it can be if you want to get fair payment for a vehicle,
                                but most dealerships offer far below the car’s actual value. We’ve built our business on
                                assisting those who are saying, ‘I want to sell my car online, and fast’. We keep a close
                                eye on the market values of vehicles to always make a fair offer.</p>
                            <p><a href="tel:1300849879">Call us today</a> or submit an online form and get a quote on your
                                car fast.</p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    },
    watch: {
        $route: {
            deep: true,
            handler(val) {
                this.pageId = val.query.id;
                this.cId = val.query.cId;
            },
        },
    },
    created() {
        this.pageId = this.$route.query.id;
        this.cId = this.$route.query.cId;
        this.pageName = this.$route.query.name;
    },
};
</script>
  
<style scoped lang="less">
.ddRow { font-family: Ubuntu-Regular, Ubuntu; color: rgba(0, 0, 0, 0.6); display: flex; align-items:center; margin: 1% 0; width: 100%; }
.ddRow h2, .ddRow h3 { font-weight: bold; color: #004AAD; margin: 4% 0 2% 0; text-align: center; }
.ddRow ul li { list-style-type: disclosure-closed; margin-bottom: 2%; margin-left: 4%; }
.ddRow img { width: 100%; }
.ddCol-12 { width: 100%; }
.ddCol-6 { width: 50%; padding: 2%; float: left; }
.ddRow:after { content: ""; display: table; clear: both; }
@media only screen and (max-device-width: 960px) { .ddRow {display: block; } .ddCol-6 { width: 100%; } }
.faq {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 286px;
        background: url("~@/assets/images/banner_3.png") no-repeat;
        background-size: cover;
        background-position: center;
        padding: 160px 0 80px 0;
        box-sizing: border-box;

        .title {
            font-size: 40px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #ffffff;
            line-height: 47px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}</style>
  